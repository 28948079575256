/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables";
@import "mixins";

// Reset and dependencies
@import "bootstrap/scss/normalize"; // Not valid for v5
@import "bootstrap/scss/print";     // Not valid for v5

// Core CSS
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";      // Not valid for v5
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";

// Components
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";    // Not valid for v5
@import "bootstrap/scss/custom-forms";   // Not valid for v5
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";      // Not valid for v5
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";          // Not valid for v5
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/responsive-embed";   // Not valid for v5
// @import "bootstrap/scss/maps";          // Only valid for v5
@import "bootstrap/scss/close";

// Components w/ JavaScript
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";

// Utility classes
@import "bootstrap/scss/utilities";
